.ReportsPage {
   display: flex;
}

.ReportsList {
    margin-top: 10vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ReportPhoto {
    width: 70%;
    height: 600px;
    background-position: center;
    background-size: contain;
    margin: 1em;
    background-repeat: no-repeat;
}