.PressPage {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.PressItem {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.PressItemRight {
    margin-top: 50px;
    width: 50%;
}

.PressItemLeft {
    margin: 50px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PressItemRight > p > span {
    font-weight: bold;
}

.PressItemLeft > img {
    width: 400px;
}