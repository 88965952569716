.ContactPage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.ContactInfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ContactIcon {
    margin: 10px;
}