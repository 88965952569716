.PapersPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin-top: 10vh;
}

.PapersList {
    width: 80%;
    padding: 0;
}

.PapersListItem {
    background: var(--green);
    padding: 2em 1em;
    margin: 1em;
    display: block;
    border: 2px solid var(--yellow);
}

.PaperData {
    display: flex;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    -ms-flex: 0 1 auto;
    flex-wrap: wrap;
    margin: 10px 0;
}

.PaperData > div {
    display: inline-flex;
    flex-direction: row;
    overflow: hidden;
    padding: 10px 40px 0px 0;
}

.PapersListItem > div > span {
    font-weight: bold;
    margin-right: 5px;
}

.PaperData > div > span {
    font-weight: bold;
    margin-right: 5px;
}