.SliderPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Slider {
    width: 90%;
    margin: 1em;
}

.SlideshowPhotoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SlideshowPhoto {
    width: 100%;
    height: 90vh;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: white;
}

.react-slideshow-container {
    z-index: 0;
}