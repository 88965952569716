.LinksPage {
    margin-top: 15vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.LinkCategory {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 3em;
}

.LinkCategory > h2 {
    text-align: left;
    width: 100%;
}

.LinkCategory > ul {
    padding: 0;
}