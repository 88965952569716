.Catalogue-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 7vh;
}

.Catalogue-page-content {
    width: 50%;
}