.NavBarList {
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-content: flex-end;
}

.Navbar-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Navbar-item > a {
    list-style: none;
    color: white;
    font-weight: bold;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Navbar-item-text {
    margin: 10px;
    font-weight: normal;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Navbar-item-text:hover {
    cursor: pointer;
    color: var(--dark-blue);
}

.Navbar-item-children {
    display: flex;
    flex-direction: column;
    background: var(--yellow);
}

.current-nav {
    color: var(--dark-blue);
}

.Navbar-item-submenu {
    background-color: var(--yellow);
    position: absolute;
    top: 6.5vh;
}

.Navbar-item-submenu > a > .Navbar-item-text {
    text-align: left;
}

.Navbar-item-subsubmenu {
    background-color: var(--yellow);
    position: absolute;
    top: 10vh;
    margin-left: 112px;
}

.Navbar-item-subsubmenu > a > .Navbar-item-text {
    text-align: left;
}