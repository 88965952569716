.Sights, .Themes {
	height: 100%;
	width: 100%;
	min-height: 100vh;
	margin-top: 10vh;
}

.Sights-list, .Themes-list {
	text-align: center;
	min-height: 100vh;
}

.Sight-item, .Theme-item {
	width: 300px;
	min-height: 300px;
	background-size: cover;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	text-align: center;
	display: inline-block;
}

.Theme-list-item {
	width: 300px;
	min-height: 300px;
	margin: 10px;
	display: inline-block;
}

.Theme-item-title {
	font-size: 1em;
	height: 80px;
	width: 100%;
	color: white;
	display: block;
	background: var(--yellow);
	margin: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.Sights-titles-list {
	margin-top: 12vh;
}

.Sight-item > p, .Theme-item > p {
	display: none;
}

.Sight-item:hover, .Theme-item:hover {
	box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}

.Sight-item:hover p {
	display: block;
}

.Sight-item:hover h2 {
	display: none;
}

.Sight {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	min-height: 100vh;
	background-size: cover;
}

.SightData {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
    align-items: center;
	margin-top: 10vh;
}

.SightDataTitle {
	margin: 2em;
	font-size: 1.5em;
	text-align: center;
}

.SightDataInfo {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content:  center;
	align-items: flex-start;
}

.SightDataDescription {
	width: 50%;
	margin: 0 1em;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	text-align: justify;
}

.SightDataSources {
	font-size: 1em;
	font-weight: bold;
	margin: 0.5em;
	margin-top: 2em;
}

.SightDataParagraph {
	margin: 0.5em;
}

.SightDataImage {
	min-height: 500px;
	margin: 1em;
	background-position: center;
	background-size: contain;
    background-repeat: no-repeat;
}

.SightDataRight {
	width: 50%;
	min-height: 500px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.SightDataExtraInfo {
	background: var(--yellow);
	padding: 1em;
	margin: 1em 0;
}

.SightDataExtraInfo > h3 > span {
	font-weight: normal;
	font-style: italic;
	white-space: pre-wrap;
}

.Slider {
	width: 90%;
}

@media (max-width: 1000px) {
	.SightDataInfo {
		flex-direction: column;
	}
	.SightDataRight {
		width: 100%;
	}
	.SightDataDescription {
		width: 90%;
	}
	.SightDataLeft {
		width: 100%;
	}
}
