.OnFocus {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	min-height: 100vh;
	width: 100%;
	margin-top: 10vh;
}

.VideosList {
	width: 80%;
	height: 100%;
	text-align: center;
}

@media (max-width: 1000px) {
	.VideosList {
		width: 100%;
	}
}

.VideoItem {
	display: inline-block;
	width: 300px;
	margin: 50px;
}

.VideoItemContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 300px;
	background-size: cover;
	color: white;
	cursor: pointer;
	text-align: center;
}

.VideoItemContent > h3 {
	margin: 0;
	background: var(--yellow);
	color: white;
	font-size: 1.1em;
	height: 115px;
	width: 300px;
	font-weight: normal;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}