#scene {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#virtual-museum-menu {
	width: 40vw;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: white;
	padding-bottom: 70px;
	border-radius: 10px;
}

#virtual-museum-menu > p {
	width: 80%;
	text-align: center;
}

#play-button {
	outline: none;
	border: none;
	border-radius: 10px;
	background-color: var(--blue);
	padding: 10px;
	cursor: pointer;
	position: absolute;
	bottom: 20px;
}

#play-button:hover {
	background-color: white;
	border: 1px solid var(--blue);
}

#painting-info {
	width: 24vw;
	left: 0;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--pink);
	border-radius: 10px;
}

#painting-info > h3 {
	margin: 10px;
}

#painting-info > p {
	margin: 10px;
}

.DigitalResources {
	margin-top: 15vh;
}

.DigitalResourcesTitle {
	text-align: center;
	margin: 5vh;
}