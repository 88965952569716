.Home-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15vh;
}

.Home-paragraph {
    padding: 0px 50px;
}