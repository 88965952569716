.Map-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 100px;
}

.Map {
    width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 20px;
}

.Map-object {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.Map-object > h1 {
	font-size: 2.5em;
	text-align: center;
}

.Map-object > img {
	width: 500px;
}

.Map-object-description {
	font-size: 1em;
	margin: 20px 0;
	padding: 0 50px;
}

.Map-filter-button {
    background: none;
    margin: 1em;
    border: 2px solid var(--dark-blue);
    border-radius: 15px;
    cursor: pointer;
    padding: 0.5em 1em;
}

.Map-filter-button:hover {
    background: var(--rose);
    color: white;
}

.active {
    background: var(--rose);
    color: white;
}

.Marker-hover-title {
    position: absolute;
    font-size: 1.5em;
    background-color: var(--dark-blue);
    padding: 5px;
    text-align: center;
    color: white;
    min-width: 300px;
    border-radius: 10px;
}