.Project, .AreaInfo {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.AreaInfo > h2 {
	padding: 0 50px;
}

.Project > h2 {
    padding: 0 50px;
}