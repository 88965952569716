.Home {
	display: flex;
    flex-direction: column;
}

.Landing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: var(--dark-blue);
	text-align: center;
	margin-top: 80px;
}

.Landing > h1 {
	font-size: 1em;
	margin: 0;
}
.Landing > h2 {
	font-size: 0.8em;
	margin: 0;
}

.Landing-video-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1em;
}

.Landing-video {
	width: 80%;
}

.Landing-image {
	width: 100%;
	height: 100%;
	background: url("./assets/img/landing-img.jpg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: absolute;
	z-index: -1;
}

.Landing-image-logo {
	width: 20vw;
	margin: 1em;
}

.Keypoints {
	height: 70vh;
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Keypoints-list {
	width: 100%;
	padding: 0;
	display: inline;
	text-align: center;
}

.Keypoints-list-item {
	width: 25%;
	display: inline-block;
}

.Keypoint {
	height: 200px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Keypoint > img {
	width: 100px;
}

.Landing-map {
	width: 80%;
	margin-top: 0;
}

.Map-selected {
	width: 40%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 10px;
}

.Map-selected > img {
	width: 80%;
}

.Main {
    height: 100vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-header {
	width: 100%;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	min-height: 10vh;
	background: var(--yellow);
	position: fixed;
	z-index: 1;
}

.App-header-nav-items {
	width: 100%;
	height: 10vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.App-header-nav-items > ul {
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
	padding: 0;
}

@media (max-width: 1100px) {
    .App-header-nav-items {
        display: none;
    }
}

.App-header-nav-item:hover {
	cursor: pointer;
	color: var(--dark-blue);
}

.App-header-logo {
	z-index: 2;
	margin: 0.5em;
}

.App-header-logo > img {
	width: 170px;
}

.App-header-nav-item {
	color: black;
}

.Menu-icon-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 1em;
}

.Menu-icon {
	width: 40px;
	z-index: 3;
	cursor: pointer;
}

.Menu {
	position: absolute;
	z-index: 1;
	left: -300px;
	width: 300px;
	height: 100vh;
	background-color: var(--yellow);
	transition: 1s;
}

.App-footer {
	background: var(--yellow);
	width: 100%;
	color: white;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.Sponsors-logos {
	width: 70%;
}

.Sponsors-logos > a > img {
	width: 5vw;
	margin: 10px;
}

.Own-logo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: var(--dark-blue);
	margin-top: 2vh;
	font-size: 0.7em;
}

.Own-logo > img {
	width: 10vw;
}
