.Idea-page {
    min-height: 100vh;
    color: var(--dark-blue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.MediumTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.MediumTitle > h2 {
    margin: 5px;
    font-weight: normal;
}

.MediumTitle > h2 > span {
    color: var(--yellow);
}

.BigLogo {
    width: 50vw;
    margin: 20px;
}

.Subtitle {
    color: var(--yellow);
    margin: 5vmin;
}

.Subsubtitle {
    color: var(--yellow);
    margin: 0;
}

.IdeaKeypoint {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.IdeaKeypointImage {
    width: 200px;
    margin: 20px 50px;
}

.IdeaKeypointText {
    width: 50%;
}

.IdeaHowList {
    width: 80%;
    display: inline-block;
}

.IdeaHowListItem {
    list-style: disc; 
}

.StructureList {
    text-align: center;
}

.StructureBox {
    width: 50vw;
    height: 100px;
    background: var(--yellow);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    display: inline-block;
    text-align: left;
}

.StructureBoxTitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 0.9em;
    height: 100%;
}

.StructureBoxTitle > h3 {
    margin-right: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.StructureBoxContent {
    display: none;
}

.StructureBoxContent > li {
    list-style: disc;
}

.SmallText {
    margin: 10vw 20vw 5vw 20vw;
}

@media (max-width: 1000px) {
    .BigLogo {
        width: 80vw;
    }
    .StructureBox {
        width: 90%;
        font-size: 0.7em;
    }
    .IdeaKeypoint {
        flex-direction: column;
    }
    .IdeaKeypointText {
        width: 100%;
    }
}